import { useCallback } from 'react';

import type { VideoCaptureSource, VideoFit, VideoFormat } from '@cofenster/constants';

import { useFileUpload } from '@cofenster/web-components';

import { Sentry } from '../../../sentry';
import { useAssetSizeLazyQuery, useInvalidateAssetMetadataCacheMutation } from '../../generated';
import { useInitVideoUpload } from './useInitVideoUpload';

export const useUploadVideoAsset = () => {
  const initVideoUpload = useInitVideoUpload();
  const uploadFile = useFileUpload();

  const [getAssetSize] = useAssetSizeLazyQuery({ fetchPolicy: 'no-cache' });
  const [invalidateAssetMetadataCache] = useInvalidateAssetMetadataCacheMutation();

  return useCallback(
    async (
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      abortSignal: AbortController['signal'],
      options?: {
        videoFormat?: VideoFormat;
        videoFit?: VideoFit;
        videoCaptureSource?: VideoCaptureSource;
      }
    ) => {
      const { videoFormat, videoFit, videoCaptureSource } = options ?? {};
      const result = await initVideoUpload(videoFormat, videoFit, videoCaptureSource);

      if (!result.data?.initVideoUpload) throw new Error('Failed to initialize video upload');

      const { uploadUrl, videoAssetId } = result.data.initVideoUpload;

      const uploadingResult = await uploadFile(uploadUrl, file, uploadId, onProgress, abortSignal, 'axios');

      if (!uploadingResult) {
        // Upload failed or was aborted
        return undefined;
      }

      const assetSizeResult = await getAssetSize({ variables: { id: videoAssetId } });
      const assetSize = assetSizeResult.data?.assetSize;

      if (!assetSize || assetSize.startsWith('0')) {
        Sentry.captureException(
          new Error(
            `The uploaded video asset (ID: ${videoAssetId}) has a file size of 0 bytes. Switching to XMLHttpRequest for the upload.`
          ),
          { level: 'error' }
        );
        const xhrUploadingResult = await uploadFile(
          uploadUrl,
          file,
          uploadId,
          onProgress,
          abortSignal,
          'XMLHttpRequest'
        );
        await invalidateAssetMetadataCache({ variables: { id: videoAssetId } });
        if (!xhrUploadingResult) {
          // Upload failed or was aborted
          return undefined;
        }
      }

      return videoAssetId;
    },
    [initVideoUpload, uploadFile, getAssetSize, invalidateAssetMetadataCache]
  );
};
